import { ActionType, productActionType } from "./productAction";

export interface IProductState {
  products: any[];
  priceTiers: any[];
  productBasicInfo: any;
  priceTierBasicInfo: any;
  productDimension: any;
  countries: Array<any>;
  packSizes: Array<any>;
  sourceProducts: Array<any>;
  UOMList: Array<any>;
  UnitConversionList: Array<any>;
  categories: Array<any>;
  finishedProducts: Array<any>;
  areas: Array<any>;
  productOtherInfo: any;
  allergens: any;
  comments: any;
  productMeasurments: any;
  filters: any[];
  dataCount: number;
  isLoading: boolean;
  isLoadingOtherInfo: boolean;
  productName: any;
  productCode: any;
  priceBandName: any;
  rackProducts: Array<any>;
  productByBestbefore: any[];
  currentTabName: any;
  currentQty?: any;
  batchDeleteReasons?: any;
}

const initialState: IProductState = {
  products: [],
  priceTiers: [],
  productBasicInfo: {},
  priceTierBasicInfo: {},
  productDimension: {},
  countries: [],
  packSizes: [],
  sourceProducts: [],
  UOMList: [],
  UnitConversionList: [],
  categories: [],
  finishedProducts: [],
  areas: [],
  productOtherInfo: {},
  allergens: {},
  comments: {},
  productMeasurments: {},
  filters: [],
  dataCount: 0,
  isLoading: false,
  isLoadingOtherInfo: false,
  productName: "",
  priceBandName: "",
  productCode: "",
  rackProducts: [],
  productByBestbefore: [],
  currentTabName: "",
  batchDeleteReasons: []
};

export const productReducer = (
  state: IProductState = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case productActionType.GET_PRODUCT_LIST_SUCCESS:
      state = {
        ...state,
        products: action.payload?.data,
        dataCount: action.payload?.dataCount,
      };
      return state;
    case productActionType.GET_PRICETIER_LIST_SUCCESS:
      state = {
        ...state,
        priceTiers: action.payload?.data,
        dataCount: action.payload?.dataCount,
      };
      return state;

    case productActionType.GET_PRODUCT_BYBEDTBEFORE_LIST_SUCCESS:
      state = {
        ...state,
        productByBestbefore: action.payload?.data,
        dataCount: action.payload?.dataCount,
      };
      return state;
    case productActionType.GET_PRICETIER_BASIC_INFO:
      state = {
        ...state,
        priceTierBasicInfo: action.payload,
        priceBandName: action.payload?.name,
      };
      return state;

    case productActionType.GET_PRODUCT_BASIC_INFO:
      state = {
        ...state,
        productBasicInfo: action.payload,
        productName: action.payload?.name,
        productCode: action.payload?.productCode
      };
      return state;

    case productActionType.GET_PRODUCT_DIMENSION:
      state = {
        ...state,
        productDimension: action.payload
      };
      return state;
    case productActionType.GET_PRODUCT_Name:
      state = {
        ...state,
        productName: action.payload,
      };
      return state;
    case productActionType.GET_PRODUCT_Code:
      state = {
        ...state,
        productCode: action.payload,
      };
      return state;
    case productActionType.SAVE_PRODUCT_OTHER_INFO:
    case productActionType.GET_PRODUCT_OTHER_INFO:
      state = {
        ...state,
        productOtherInfo: action.payload?.data,
      };
      return state;
    case productActionType.GET_PRODUCT_ALLERGENS:
      state = {
        ...state,
        allergens: action.payload?.data,
      };
      return state;
    case productActionType.SAVE_PRODUCT_COMMENTS:
    case productActionType.GET_PRODUCT_COMMENTS:
      state = {
        ...state,
        comments: action.payload?.data,
      };
      return state;

    case productActionType.GET_PRODUCT_MEASURMENTS:
      state = {
        ...state,
        productMeasurments: action.payload?.data,
      };
      return state;
    case productActionType.GET_COUNTRIES:
      state = {
        ...state,
        countries: action.payload?.data,
      };
      return state;
    case productActionType.GET_UOMLIST:
      state = {
        ...state,
        UOMList: action.payload?.data,
      };
      return state;
    case productActionType.GET_UnitConversionLIST:
      state = {
        ...state,
        UnitConversionList: action.payload?.data,
      };
      return state;
    case productActionType.GET_PACKSIZES:
      state = {
        ...state,
        packSizes: action.payload?.data,
      };
      return state;
    case productActionType.GET_SOURCE_PRODUCTS:
      state = {
        ...state,
        sourceProducts: [
          { label: " Select Source Product ", value: "" },
        ].concat(action.payload?.data),
      };
      return state;
    case productActionType.GET_CATEGORIES:
      state = {
        ...state,
        categories: [{ label: " Select Category ", value: "" }].concat(
          action.payload?.data
        ),
      };
      return state;
    case productActionType.GET_AREAS:
      state = {
        ...state,
        areas: action.payload?.data,
      };
      return state;
    case productActionType.LOAD_PRODUCT:
      state = {
        ...state,
        isLoading: action.payload,
      };
      return state;
    case productActionType.LOAD_FINISHED_PRODUCTS:
      state = {
        ...state,
        finishedProducts: action.payload?.data,
      };
      return state;
    case productActionType.GET_RACK_Products:
      state = {
        ...state,
        rackProducts: action.payload?.data,
      };
      return state;
    case productActionType.UPDATE_TAB_NAME:
      state = {
        ...state,
        currentTabName: action.payload,
      };
      return state;
    case productActionType.UPDATE_Qty_In_Stock:
      state = {
        ...state,
        currentQty: action.payload,
      };
      return state;

    case productActionType.GET_Batch_Delete_Reasons:
      state = {
        ...state,
        batchDeleteReasons: action.payload,
      };
      return state;

    default:
      return state;
  }
};
